import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyAZMfUXT5CNR4Qwd-VET3msg4ckK693c24",
  authDomain: "meditation-buddy-1a5e1.firebaseapp.com",
  projectId: "meditation-buddy-1a5e1",
  storageBucket: "meditation-buddy-1a5e1.appspot.com",
  messagingSenderId: "392723187304",
  appId: "1:392723187304:web:12316bf144ba3d48589ddd"
};

const firebase = Firebase.initializeApp(config);
const { FieldValue } = Firebase.firestore;

export { firebase, FieldValue };
